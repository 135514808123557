@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Noto+Sans:wght@300;400&family=Oswald&family=Playfair+Display:wght@400;500;600&family=Poppins:wght@300;400;500&display=swap');
/* font-family: 'Lato', sans-serif;
font-family: 'Noto Sans', sans-serif;
font-family: 'Oswald', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif; */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
 