@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Noto+Sans:wght@300;400&family=Oswald&family=Playfair+Display:wght@400;500;600&family=Poppins:wght@300;400;500&display=swap');
.divPrimario{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 0;
  
}

.divSecundario{
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px 30px;
  width: 400px;
  justify-content: center;
  border-radius: 15px;
  font-family: 'Poppins', sans-serif;
  
  
  
}



.pForm {
  padding: 15px 0;
    
}

input {
  border: none;
  padding: 10px 15px;
  background-color: rgb(239, 239, 239);
  border-radius: 15px;
  width: 250px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

textarea { 
  background-color: rgb(239, 239, 239);
  border-radius: 15px;
  border: none;
  padding: 10px 15px;
  width: 250px;
  max-height: 150px;
  min-height: 100px;
  min-width: 250px;
  max-width: 250px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
}



.divForm{
  padding: 15px 0;
}
.boton{
  padding: 10px 16px;
  background: linear-gradient(90deg, rgba(103,96,217,1) 0%, rgba(114,59,173,1) 100%);
  color: #ffff;
  transition: 0.2s ease;
  cursor: pointer;
  opacity: 0.8;
  transition: all .2s ease-in-out;
  
  
}
.boton:hover{
  opacity: 1;
  transform: scale(1.02);

}

.lightbox {
  position: fixed;
  z-index: 1000;
  top:0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.866);
    display: flex;
  justify-content: center;
  align-items: center;
  
}


.lightbox img {
  max-width: 95%;
  max-height: 95%;
  padding: 5px;

}

.lightbox button {
  font-size: 40px;
  position: fixed;
  top: 0;
  color: #ffff;
  right: 0;
  padding: 5px 20px;
  cursor: pointer;
   background-color:rgba(0, 0, 0, 0);
  border: none;
}
.lightbox button:hover { 
  transition: all .2s ease-in-out;
  transform: scale(1.2);

}
